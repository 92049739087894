import React, { useEffect, useState } from 'react'
import { Smartform } from './Smartform'
import Title from 'antd/lib/typography/Title'

import ProMach_IPAK_RFQ from '../../../../dev/forms/ProMach/IPAK_RFQ.form.json'
import Titan_MK1369 from '../../../../dev/forms/Titan/Titan_MK1369.form.json'
import Titan_MK1371 from '../../../../dev/forms/Titan/Titan_MK1371.form.json'
import Titan_MK1422 from '../../../../dev/forms/Titan/Titan_MK1422.form.json'
import Titan_MK1444 from '../../../../dev/forms/Titan/Titan_MK1444.form.json'
import Titan_MK1500 from '../../../../dev/forms/Titan/Titan_MK1500.form.json'

/**
 * Handy way to inject form data directly for development purposes.
 */
const ASSET_OVERRIDE = {
  'ProMach_IPAK_RFQ.form': ProMach_IPAK_RFQ,
  'Titan_MK1369.form': Titan_MK1369,
  'Titan_MK1371.form': Titan_MK1371,
  'Titan_MK1422.form': Titan_MK1422,
  'Titan_MK1444.form': Titan_MK1444,
  'Titan_MK1500.form': Titan_MK1500,
}

/**
 * @typedef {{ id: string, extension: 'form', filename: string, title: string, url: string }} Asset
 * @param {{ asset: Asset }} props
 */
export function FormPreview(props) {
  const { asset } = props
  // BEGIN TODO: the following is what *should* be happening...the asset should be fetched from
  // its URL.  However, for form assets, the CORS headers are not set, which is preventing
  // the asset from loading.  Until that problem is resolved, we are hardcoding the assets
  const [formConfig, setFormConfig] = useState(undefined)
  useEffect(() => {
    const formConfig = ASSET_OVERRIDE[asset.filename]
    if (formConfig) setFormConfig(formConfig)
    else
      fetch(props.asset.url)
        .then((res) => res.json())
        .then((data) => {
          setFormConfig(data)
        })
        .catch((err) => {
          console.log({ err })
        })
  }, [props.asset.url])

  // END TODO
  if (formConfig == undefined)
    return (
      <div style={{ marginTop: 200, height: 800 }}>
        This form cannot yet be rendered on the web.
      </div>
    )
  return (
    <div style={{ marginTop: '2em' }}>
      <Smartform formConfig={formConfig} />
    </div>
  )
}
